<template>
  <div
    class="bg-white dark:bg-jevi-dark w-full px-5 flex flex-col justify-center items-center h-screen overflow-y-auto"
  >
    <div class="w-full h-full">
      <div class="my-5">
        <iframe
          style="border-radius: 12px"
          src="https://open.spotify.com/embed/playlist/37i9dQZF1DX1clOuib1KtQ?utm_source=generator"
          width="100%"
          height="380"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>

      <div class="my-5">
        <iframe
          style="border-radius: 12px"
          src="https://open.spotify.com/embed/playlist/4c6JNaUHpYiIgUNW6yg0Vh?utm_source=generator"
          width="100%"
          height="380"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios")
const notify = () => import("@/utils/notify.js")

export default {
  components: {},
  data() {
    return {
      loading: true
    }
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {},
  async created() {
    // Set global theme
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)")
    mediaQueryList.addEventListener("change", (event) => {
      this.$store.dispatch("setTheme", event.matches ? "dark" : "light")
    })
    this.$store.dispatch("setTheme", mediaQueryList.matches ? "dark" : "light")
  },
  watch: {}
}
</script>
